@font-face {
  font-family: Roboto-Medium;
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

body {
  background-color: #d7d8da !important;
  background: #d7d8da !important;
  /* background-color: #f2f2f2 !important; */
  font-family: Roboto-Regular;
}

.navbar-inverse .navbar-nav>li>a {
  color: #fff !important;
}

.page-header-content {
  background-color: #d7d8da !important;
}

.navbar-header {
  background-color: #000 !important;
  /* padding-bottom: 10px !important; */
}

.text-danger {
  color: #ff0000 !important;
  font-size: 11px !important;
}

.rowidth {
  width: 180px !important;
}

.droppad {
  padding-top: 4px !important;
}

.airplanetext1 {
  margin-top: 10px !important;
  color: #1976d3 !important;
}

.airplanetext {
  margin-top: 10px !important;
  color: #1976d3 !important;
  font-weight: 600;
  /* letter-spacing: 2px; */
}

.icon-more {
  color: #000000;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.p-30 {
  padding-left: 30px;
}

.p-22 {
  padding-left: 23px;
}

.blue {
  background: #f2f2f2;
  color: #000 !important;
  text-align: center;
  padding: 30px 10px !important;
}

.no-wrap-container {
  display: flex;
  flex-wrap: nowrap;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

a {
  cursor: pointer !important;
}

.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #1976d3 !important;
  color: #fff !important;
}

.btn-circle:hover {
  background-color: #1976d3;
  color: #fff;
}

.srv-validation-message {
  color: red !important;
}

.formControl {
  color: #000 !important;
}

.btn-default:hover {
  background-color: #1976d3;
  border-color: #1976d3;
  color: #fff;
}

.noOutline {
  outline: none;
}

.padding-cs {
  padding: 0px 4%;
}

.dFlex {
  display: flex;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.table-responsive .row {
  /*
 display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: stretch;
    margin:0px;
*/
}

.input-group-btn {
  display: none;
}

.table-responsive .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.table-responsive .form-control {
  margin: 0px 10px;
  border-radius: 1px !important;
  border-width: 1px !important;
  height: auto !important;
  font-family: Roboto-Regular !important;
  border: 1px solid #ccc !important;
}

#formGroupPagination {
  padding: 6px 20px 6px 18px !important;
  color: #000000;
}

.table-datatable {
  margin-top: 20px;
}

.User-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.User-badge button,
.integrationBtn {
  background: #1976d3 !important;
  color: #fff !important;
  padding: 8px 16px !important;
  border-radius: 20px !important;
  border: 0px !important;
  margin: 10px;
  min-width: 108px;
}

.User-badge .dropboxButton {
  background-color: rgb(255, 255, 255) !important;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px !important;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.User-badge .dropboxSpan {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}

.btn-group-page-nav button {
  border-radius: 0px !important;
}

.User-badge button div {
  display: none;
}

.User-badge button span {
  padding: 0px !important;
}

.table-datatable thead {
  border-color: #9f9f9f;
  border-width: 1px 0px;
  border-style: solid;
}

.User-badge .cancelBtn {
  background: #7a7a7a !important;
}

.form-control {
  font-size: 16px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Roboto-Regular;
  color: #333 !important;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Roboto-Regular;
  color: #333 !important;
  font-size: 14px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: Roboto-Regular;
  color: #333 !important;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumb a {
  text-decoration: none;
  background-color: none !important;
}

.breadcrumb {
  background-color: #fff !important;
}

.breadcrumb-line {
  background-color: #fff !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: 0px solid #d7d8da !important;
}

.thead-default {
  background-color: #f2f2f2 !important;
  border: 0px solid #000 !important;
}

.modal {
  display: flex !important;
  align-items: center !important;
}

/* .dropdown-cs .active {
  color: #1976d3;
} */

.navigation-main>.sidenav>.active {
  background-color: #1976d3 !important;
}

.srv-validation-message {
  color: #ff0000d4 !important;
  padding: 5px 0px !important;
  font-size: 12px !important;
}

.disable {
  pointer-events: none !important;
}

.disableCursor {
  cursor: not-allowed !important;
}

.modal-header .close {
  opacity: 1;
}

.table-responsive {
  min-height: 300px;
}

thead th {
  font-family: Roboto-Medium;
  min-width: 115px;
}

.table-responsive .input-group input {
  margin: 0px;
  color: #000;
}

.mau {
  font-size: 18px !important;
}

.icon-blocked {
  font-size: 19px !important;
}

.mt-0 {
  margin-top: 0px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.parent img {
  width: 100px;
  position: fixed;
  overflow: hidden;
  z-index: 9;
  top: 45% !important;
}

.bb {
  border: 1px solid #9f9f9f;
  margin: 0px 20px 0px 15px;
}

.margbtm {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 768px) {
  .table-responsive .form-group {
    padding: 10px 0px;
    justify-content: center;
  }

  .table-responsive .col-xs-12 {
    text-align: center;
  }

  .paidContent .row {
    margin: 0px;
  }

  .savetop {
    text-align: center;
  }

  .update,
  .row-eq-height {
    width: 100% !important;
  }

  .row-eq-height {
    flex-wrap: wrap;
  }
}

.navigation>li>a,
.navigation li a:focus,
.navigation li a:hover {
  text-decoration: none;
}




.addsp {
  margin: 0px 10px;
}

.addu {
  font-size: 16px;
  color: #333;
  font-family: Roboto-Medium;
}

.login-box-body {
  color: #333 !important;
}

.textDecN {
  text-decoration: none !important;
}

.disableCursor {
  opacity: 0.6;
}

.disable {
  pointer-events: none !important;
}

.disableCursor {
  cursor: not-allowed !important;
}

.navigation li a>i {
  margin-top: 5px !important;
}


.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 250px;
  height: 300px;
  overflow: scroll;
}

.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -500px;
}

.ReactTags_suggestions {
  position: relative;
  z-index: 2999
}

.listView .active i {
  color: #1976d3;
}

.left-day-picker .DayPickerInput-OverlayWrapper {
  position: absolute;
  right: 271px;
}

.adiedi {
  color: #999;
  font-size: 11px;
  font-family: Roboto-Regular;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

html,
body {

  height: 100%;
  width: 100%;
}

.w-100 {
  width: 100%;
}

[class^='icon-'],
[class*=' icon-'] {

  cursor: pointer;
}

.activeCatg {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidenav .activeTabs.active {
  /* background: #e5e5e5; */
  background: #1976d3 !important;
}

#sideNav .activeDoc {
  background-color: #1976d3 !important;
}

.paddbtm {
  padding-bottom: 0px !important;
}

.paddtp {
  padding-top: 0px !important;
}

.pagepadding {
  padding: 7px 15px !important;
  display: flex !important;
  justify-content: center !important;
}

.pagepadding {
  margin-top: -70px;
}

.displayBreadcrum {
  display: none !important;
}

.breadcrumb-line .breadcrum-pad {
  padding-top: 10px !important;
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.planeimg {
  border: 2px solid #1976d3 !important;
}




.iconpad {
  padding: 4px 0px 0px 2px !important;
}

#sideNav .navstyle:hover {
  background-color: #000 !important;
}

.navstyle {
  padding-left: 18px !important;
  width: 100% !important;
  background-color: #000 !important;
  padding-bottom: 13px !important;
}

#sideNav {
  padding-top: 0px !important;
}

/* .tooltip {
  position: relative !important;
  display: inline-block !important;
  border-bottom: 1px dotted black !important;
}

.tooltip .tooltiptext {
  visibility: hidden !important;
  width: 120px;
  background-color: black !important;
  color: #fff !important;
  text-align: center !important;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
/* position: absolute !important;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible !important;
}  */

@media only screen and (max-width: 1024px) {
  .modal {
    display: flex !important;
    align-items: unset !important;
  }
}


@media only screen and (max-width: 990px) and (min-width: 600px) {
  .table-responsive .form-group {
    width: auto !important;
  }

  .tbody-td-default {
    text-align: left !important;
  }
}

.sidebar {
  overflow-x: hidden;
}

.documentFlow {
  height: 20px;
}

.paddingTop {
  padding: 10px !important;
}

.table-search {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
}

.table-search input {
  border: 1px solid #dddddd;
  padding: 8px;
  border-radius: 6px;
}

.react-bs-table-sizePerPage-dropdown .btn-default:focus,
.react-bs-table-sizePerPage-dropdown .btn-default:hover {
  color: #000 !important;
}

#root,
#root>div,
#root>div>div,
#root .page-container {
  height: 100% !important;
}

.img_cls {
  float: none !important;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
}

.modal-open .modal {
  flex-direction: column;
}


@media (min-width: 769px) {
  .sidebar-xs .sidebar-main .img_cls {
    margin-right: 5px;
  }
}

.table-responsive .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.btn-group-page-nav button {
  color: #f9f9f9 !important;
  background: #337ab7 !important;
}

.table-responsive .btn.active{
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}